<template>
  <div class="privacy">
    <PageHeader is-dark>
      Privacy Policy
    </PageHeader>
    <section class="privacy-content">
      <p>
        UnGhosted™ respects your privacy and is committed to protecting your
        personal information that you provide to us. This statement explains our
        policies and practices regarding the use and disclosure of your personal
        information by UnGhosted™. Please note that UnGhosted™ reviews and
        updates this Privacy Policy from time to time as needed without notice.
        Therefore, you should review the terms of this policy periodically to
        make sure that you are aware of how UnGhosted™ collects and uses
        personal information. By using our website, you consent to the
        collection and use of your personal information by UnGhosted™ as
        explained below.
      </p>
      <h3>1. Personal Information.</h3>
      <p>
        You have complete control over your personal information. In general,
        you can visit our website without providing us with any personal
        information. However, there are instances where we must have your
        personal information in order for us to grant you access to our
        protected and secured sites. This information may include registration
        data (your name, address, email address, phone number, title, etc.),
        information request data and response data (“User Information”). When
        you receive your confirmation email or when you receive any email from
        the list server, you will be given instructions on how to remove
        yourself from the list.
      </p>
      <h3>2. Use of User Information.</h3>
      <p>
        We intend to use such information for purposes of supporting you and
        various companies with UnGhosted™ by designing a website content that is
        suitable to your needs and alerting you to new product and service
        offerings as they become available. This User Information may be
        retained by UnGhosted™ to verify compliance with the agreement between
        UnGhosted™ and your employer, to keep track of the domains from which
        people visit us, to create a user profile to better serve you and your
        employer, or to simply contact you either electronically or otherwise.
        If you decide that we should not use your personal User Information to
        contact you, please let us know and we will not use that information for
        such purpose. However, please do not submit any User Information if you
        are less than 18 years of age.
      </p>
      <h3>3. Disclosure of User Information.</h3>
      <p>
        UnGhosted™ does not sell, trade or transfer User Information to third
        parties. However, we may share User Information with our business
        partners for marketing, advertising or product/service offering
        purposes. For example, we provide User Information to our service
        providers for direct emailing of our newsletters, online surveys or
        notifications on UnGhosted™ to our viewers. We also disclose User
        Information if: we have your consent; we need to share it in order to
        provide you with the products and/or services you requested; we respond
        to a court order or you violate our Terms of Use. You may separately
        agree to provide your personal information to third parties that provide
        content for UnGhosted™ in order to access and/or use their products
        and/or services. If you agree to provide such information to these third
        parties, then your personal information will be subject to their privacy
        policies.
      </p>
      <h3>4. Accuracy and Security.</h3>
      <p>
        The accuracy and security of the User Information is important to
        UnGhosted™. Currently, you may review and request updates to your User
        Information retained by contacting UnGhosted™. If you contact us to
        correct your User Information, we will attempt to correct such
        inaccuracies in a timely manner. UnGhosted™ is concerned with the
        security of your User Information and is committed to taking reasonable
        steps to protect it from unauthorized access and use of that personal
        information. To that end, we put in place the appropriate physical,
        electronic and managerial policies and procedures to secure your
        personal User Information. We also continue to implement procedures to
        maintain accurate, complete and current User Information.
      </p>
      <h3>5. Usernames and Passwords.</h3>
      <p>
        Access to certain content on our website may be allowed under a written
        agreement between you or your employer and UnGhosted™ and will require a
        username and/or password. In some cases, failure to provide personal
        information may prevent you from accessing certain UnGhosted™ website(s)
        containing certain confidential information, products, services, or
        promotional offers. By accessing and using our protected and secured
        website(s), you agree to maintain the confidentiality of the username
        and password you selected to access such site(s) and consent to our
        Terms of Use.
      </p>
      <h3>6. Cookies.</h3>
      <p>
        UnGhosted™ uses “cookies”. A cookie is a small data file that a web site
        can transfer to a visitor’s hard drive to keep records of the visits to
        such a site. A cookie contains information such as your username and
        password that helps us recognize the pages you have visited and improve
        future visits, but the only personal information a cookie can contain is
        the information that you provide yourself. A cookie cannot read data off
        of your hard drive or read cookie files created by other sites.
        Information stored in cookies may be encrypted, however, we do not store
        your credit card number in cookies. If you prefer not to accept a
        cookie, you can set your web browser to warn you before accepting
        cookies or you can refuse all cookies by turning them off in your web
        browser. However, access to some of our secured website(s) may require
        the use of cookies, which you must enable only cookies that get sent
        back to the originating server. Otherwise, you can still access most of
        the features on our web site even without accepting a cookie.
      </p>
      <h3>7. External Links.</h3>
      <p>
        UnGhosted™ website provides links to other third party websites. Even if
        the third party is affiliated with UnGhosted™ through a business
        partnership or otherwise, UnGhosted™ is not responsible for the privacy
        policies or practices or the content of such external links. These links
        are provided to you for convenience purposes only and you access them at
        your own risk.
      </p>
      <h3>8. Terms of Use.</h3>
      <p>
        Please also see our Terms of Use, which describes the restrictions,
        disclaimers, indemnification and limitation of liability governing the
        use of the entire UnGhosted™ website.
      </p>
      <h3>9. General.</h3>
      <p>
        If you have questions regarding our Privacy Statement, please contact us
        via our Contact page.
      </p>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'Privacy',
  components: {
    PageHeader
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.privacy {
  @include default-page-paddings;

  background: rgba(19, 33, 63, 0.89);

  &-content {
    margin: 80px auto 0;
    max-width: 660px;
    color: var(--contrast-text-color);

    h3 {
      margin-bottom: 5px;
    }

    @include breakpoint-reverse(small) {
      margin-top: 40px;
    }
  }

  @include breakpoint-reverse(medium) {
    padding: 37px 24px;
  }

  @include breakpoint-reverse(small) {
    margin-left: 0;
    padding: 12px;
  }
}
</style>
